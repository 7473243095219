<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: Febrero 2024                                                  ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <v-main style="padding: 0 1rem !important; padding-bottom: 2rem; overflow-y: auto; height: 84vh;">

    <v-container>
      <!-- Encabezado de datos personales -->
      <v-row class="ma-0">
        <v-col cols="12" md="12" class="pa-0">
          <div class="d-flex justify-center">
            <span class="tituloDatos mb-0">Datos personales</span>
          </div>
          <div class="linea"></div>
        </v-col>
      </v-row>

      <!-- Detalle de datos personales -->
      <v-row style="margin: 0;">
        <v-col cols="12" md="11" style="margin: 0;" class="pa-1">
          <v-row style="margin: 0;">
            <v-col cols="12" md="4" class="pa-1">
              <span class="tituloCampo">Nombres: <span class="datosCampo">{{ nombres }}</span></span>
            </v-col>
            <v-col cols="12" md="4" class="pa-1">
              <span class="tituloCampo">Apellidos: <span class="datosCampo">{{ apellidos }}</span></span>
            </v-col>
            <v-col cols="12" md="4" class="pa-1">
              <span class="tituloCampo">{{ tipoId }}: <span class="datosCampo">{{ identificacion }}</span></span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="1" style="margin: 0;" class="d-flex align-center pa-1">
          <v-row class="d-flex justify-center">
            <v-col cols="1" md="1" class="pa-0">
              <v-tooltip v-if="!screenSmaller" left color="#0d47a1">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon @mousedown.prevent="dialogContrasena = true" v-bind="attrs" v-on="on">
                    <v-icon color="#0d47a1">lock_person</v-icon>
                  </v-btn>
                </template>
                <span>Cambiar contraseña</span>
              </v-tooltip>
              <v-btn v-else icon @click="dialogContrasena = true">
                <v-icon color="#0d47a1">lock_person</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- Fila para cuando en base de datos ya existe un documento -->
      <v-row v-if="documentoActual !== null" class="ma-0">
        <v-col cols="12" md="6" class="d-flex align-end pa-1">
            <span style="color: black;" class="pb-1">Documento de identificación digital: </span>
            <v-tooltip v-if="!screenSmaller" right color="#0d47a1">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @mousedown.prevent="verDocumentoDigital()" v-bind="attrs" v-on="on">
                  <v-icon medium color="#0d47a1">visibility</v-icon>
                </v-btn>
              </template>
              <span>Ver documento</span>
          </v-tooltip>
          <v-btn v-else icon @click="verDocumentoDigital()">
            <v-icon medium color="#0d47a1">visibility</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <!-- encabezado de datos de contacto -->
      <v-row class="ma-0">
        <v-col cols="12" md="12" class="pa-0">
          <div class="d-flex justify-center">
            <span class="tituloDatos mb-0">Datos de contacto</span>
          </div>
          <div class="linea"></div>
        </v-col>
      </v-row>

      <!-- Detalle de datos de contacto -->
      <v-row class="ma-0">
        <v-col cols="12" md="11" style="margin: 0;" class="pa-1">
          <v-row class="ma-0">
            <v-col cols="12" md="4" class="pa-0">
              <span class="tituloCampo">Departamento: <span class="datosCampo">{{ departamento }}</span></span>
            </v-col>
            <v-col cols="12" md="4" class="pa-0">
              <span class="tituloCampo">Ciudad: <span class="datosCampo">{{ ciudad }}</span></span>
            </v-col>
            <v-col cols="12" md="4" class="pa-0">
              <span v-if="tipoDirecionDelpaciente" class="tituloCampo">Dirección: <span class="datosCampo">{{ direccionFija }}, {{ barrioSelecionado === 'OTRO' ? barrioOpcional : barrioSelecionado }}{{ `${ detalleDireccion !== '' ? ', ' + detalleDireccion : ''}` }}</span></span>
              <span v-else class="tituloCampo">Dirección: <span class="datosCampo">{{ direccionFija }}{{ `${ detalleDireccion !== '' ? ', ' + detalleDireccion : ''}` }}</span></span>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col cols="12" md="4" class="pa-0">
              <span class="tituloCampo">Celular: <span class="datosCampo">{{ celular }}</span></span>
            </v-col>
            <v-col cols="12" md="4" class="pa-0">
              <span class="tituloCampo">Teléfono: <span class="datosCampo">{{ telefonoFijo }}</span></span>
            </v-col>
            <v-col cols="12" md="4" class="pa-0">
              <span class="tituloCampo">Correo: <span class="datosCampo">{{ correo }}</span></span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="1" style="margin: 0;" class="d-flex align-center pa-1">
          <v-row class="d-flex justify-center">
            <v-col cols="1" md="1" class="pa-0">
              <v-tooltip v-if="!screenSmaller" left color="#0d47a1">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon @mousedown.prevent="abrirDialog()" v-bind="attrs" v-on="on">
                    <v-icon color="#0d47a1">border_color</v-icon>
                  </v-btn>
                </template>
                <span>Actualizar contacto</span>
              </v-tooltip>
              <v-btn v-else icon @click="abrirDialog()">
                <v-icon color="#0d47a1">border_color</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      
      <!-- Linea de separación entre los datos de contacto y los input para los documentos -->
      <v-row class="ma-0 mt-3">
        <v-col cols="12" md="12" class="pa-0">
          <div class="linea"></div>
        </v-col>
      </v-row>

      <!-- Campo para visualizar cuando hay una solicitud en proceso -->
      <v-row v-if="solicitudPendiente === true && solicitudAprovada !== true" class="ma-0 mt-3">
        <v-col cols="12" md="12" class="pa-0">
          <div class="d-flex justify-center">
            <v-alert class="mt-3" color="primary" transition="scale-transition" outlined dense>
              <div>
                <span style="font-size: large;" v-html="mensajePrincipal !== '' ? mensajePrincipal : parteProcesada(mensajeSolicitudPendiente)"></span>
                <div v-if="mensajePrincipal !== ''" class="d-flex justify-start">
                  <ul>
                    <li v-for="(solicitud, index) in partesProcesadas" :key="index" v-html="solicitud.numero + ' ' + solicitud.estado" style="font-size: large;"></li>
                  </ul>
                </div>
              </div>
            </v-alert>
          </div>
          <span class="d-flex justify-center" style="color: #0d47a1;">Nota: Ingresa al historial para más información.</span>
          <div v-if="solicitudesEnProceso && solicitudesEnProceso < 4" class="d-flex justify-center mt-2 mb-2">
            <v-btn small rounded color="#0d47a1" class="white--text" @click="solicitudPendiente = false">Crear nueva solicitud</v-btn>
          </div>
        </v-col>
      </v-row>

      <!-- Subir documento - Copia del documento -->
      <v-row v-if="solicitudPendiente === false && documentoActual === null" class="ma-0">
        <v-col cols="12" md="11" style="margin: 0;" class="pa-1">
          <v-row class="ma-0">
            <v-col cols="12" md="3" class="d-flex align-center justify-start pa-0">
              <span style="color: #0d47a1;">Documento de identidad <span style="color: red">*</span></span>
            </v-col>
            <v-col cols="12" md="9" style="height: 4rem" class="pl-0 pr-0">
              <validation-provider v-slot="{ errors }" class="mr-4">
                <v-file-input class="file" accept=".pdf, .jpg, .png, .jpeg, .tiff," ref="documentoIdentidadFile" prefix="Seleccionar archivo" prepend-icon :clearable="true" :truncate-length="sizeText"
                  outlined dense :error-messages="formatoIncorrecto.documento || errors" @change="cargarDocumento('documento')" @click:clear="borrarDocumento('documento')">
                </v-file-input>
              </validation-provider>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="1" style="margin: 0;" class="d-flex align-center pa-1">
          <v-row class="d-flex justify-center">
            <v-col v-if="documentos.documento !== '' && documentos.documento !== null" cols="1" md="1" class="text-center d-flex pa-0">
              <v-tooltip v-if="!screenSmaller" left color="#0d47a1">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon @click="borrarDocumentoIcono('documento')">
                    <v-icon medium color="error">delete</v-icon>
                  </v-btn>
                  <v-btn icon @mousedown.prevent="abrirDocumento('documento')" v-bind="attrs" v-on="on">
                    <v-icon medium color="#0d47a1">visibility</v-icon>
                  </v-btn>
                </template>
                <span>Ver documento</span>
              </v-tooltip>
              <div v-else>
                <v-btn icon @click="borrarDocumentoIcono('documento')">
                  <v-icon medium color="error">delete</v-icon>
                </v-btn>
                <v-btn icon @click="abrirDocumento('documento')">
                  <v-icon medium color="#0d47a1">visibility</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- Dialogo que usa el fórmulario del componente AddressForm.vue-->
      <v-dialog v-model="componenteAddress" persistent transition="dialog-bottom-transition" max-width="50rem">
        <keep-alive>
          <component 
            :is="formularioDireccion.component"
            :direccion="direccionSelecionada"
            :municipio="municipioSeleccionado"
            :idMunicipio="idCiudad"
            :nombreBarrio="barrioSelecionado"
            :nombreBarrioOpcional="barrioOpcional"
            :titulo="formularioDireccion.title"
            @close-address="componenteAddress = false"
            @direccion-guardada="actualizarDireccion"
            ref="addressComponent">
          </component>
        </keep-alive>
      </v-dialog>
      
      <!-- Subir documento - Copia de la fórmula médica -->
      <v-row v-if="solicitudPendiente === false" class="ma-0">
        <v-col cols="12" md="11" style="margin: 0;" class="pa-1">
          <v-row class="ma-0">
            <v-col cols="12" md="3" class="d-flex align-center justify-start pa-0">
              <span style="color: #0d47a1;">Fórmula médica / Mipres <span style="color: red">*</span></span>
            </v-col>
            <v-col cols="12" md="9" style="height: 4rem" class="pl-0 pr-0">
              <validation-provider v-slot="{ errors }" class="mr-4">
                <v-file-input class="file" accept=".pdf, .jpg, .png, .jpeg, .tiff," ref="formulaMedicaFile" prefix="Seleccionar archivo" prepend-icon :clearable="true" :truncate-length="sizeText"
                  outlined dense :error-messages="formatoIncorrecto.formulaMedica || errors" @change="cargarDocumento('formulaMedica')" @click:clear="borrarDocumento('formulaMedica')">
                </v-file-input>
              </validation-provider>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="1" style="margin: 0;" class="d-flex align-center pa-1">
          <v-row class="d-flex justify-center">
            <v-col v-if="documentos.formulaMedica !== '' && documentos.formulaMedica !== null" cols="1" md="1" class="text-center d-flex pa-0">
              <v-tooltip v-if="!screenSmaller" left color="#0d47a1">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon @click="borrarDocumentoIcono('formulaMedica')">
                    <v-icon medium color="error">delete</v-icon>
                  </v-btn>
                  <v-btn icon @mousedown.prevent="abrirDocumento('formulaMedica')" v-bind="attrs" v-on="on">
                    <v-icon medium color="#0d47a1">visibility</v-icon>
                  </v-btn>
                </template>
                <span>Ver fórmula médica</span>
              </v-tooltip>
              <div v-else>
                <v-btn icon @click="borrarDocumentoIcono('formulaMedica')">
                  <v-icon medium color="error">delete</v-icon>
                </v-btn>
                <v-btn icon @click="abrirDocumento('formulaMedica')">
                  <v-icon medium color="#0d47a1">visibility</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      
      <!-- Subir documento - Copia de la autorización médica -->
      <v-row v-if="solicitudPendiente === false" class="ma-0">
        <v-col cols="12" md="11" style="margin: 0;" class="pa-1">
          <v-row class="ma-0">
            <v-col cols="12" md="3" class="d-flex align-center justify-start pa-0">
              <span style="color: #0d47a1;">Autorización médica</span>
            </v-col>
            <v-col cols="12" md="9" style="height: 4rem" class="pl-0 pr-0">
              <validation-provider v-slot="{ errors }" class="mr-4">
                <v-file-input class="file" accept=".pdf, .jpg, .png, .jpeg, .tiff," ref="autorizacionMedicaFile" prefix="Seleccionar archivo" prepend-icon :clearable="true" :truncate-length="sizeText"
                  outlined dense :error-messages="formatoIncorrecto.autorizacionMedica || errors" @change="cargarDocumento('autorizacionMedica')" @click:clear="borrarDocumento('autorizacionMedica')">
                </v-file-input>
              </validation-provider>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="1" style="margin: 0;" class="d-flex align-center pa-1">
          <v-row class="d-flex justify-center">
            <v-col v-if="documentos.autorizacionMedica !== '' && documentos.autorizacionMedica !== null" cols="1" md="1" class="text-center d-flex pa-0">
              <v-tooltip v-if="!screenSmaller" left color="#0d47a1">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon @click="borrarDocumentoIcono('autorizacionMedica')">
                    <v-icon medium color="error">delete</v-icon>
                  </v-btn>
                  <v-btn icon @mousedown.prevent="abrirDocumento('autorizacionMedica')" v-bind="attrs" v-on="on">
                    <v-icon medium color="#0d47a1">visibility</v-icon>
                  </v-btn>
                </template>
                <span>Ver autorización médica</span>
              </v-tooltip>
              <div v-else>
                <v-btn icon @click="borrarDocumentoIcono('autorizacionMedica')">
                  <v-icon medium color="error">delete</v-icon>
                </v-btn>
                <v-btn icon @click="abrirDocumento('autorizacionMedica')">
                  <v-icon medium color="#0d47a1">visibility</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      
      <!-- Subir documento - Comprobante de pago Cuota moderadora o Copago -->
      <!-- <v-row v-if="solicitudPendiente === false" class="ma-0">
        <v-col cols="12" md="11" style="margin: 0;" class="pa-1">
          <v-row class="ma-0">
            <v-col cols="12" md="3" class="d-flex align-center justify-start pa-0">
              <span style="color: #0d47a1;">Copago</span>
            </v-col>
            <v-col cols="12" md="9" style="height: 4rem" class="pl-0 pr-0">
              <validation-provider v-slot="{ errors }" class="mr-4">
                <v-file-input class="file" accept=".pdf, .jpg, .png, .jpeg, .tiff," ref="cuotaModeradaFile" prefix="Seleccionar archivo" prepend-icon :clearable="true"
                  outlined dense :error-messages="formatoIncorrecto.cuotaModerada || errors" @change="cargarDocumento('cuotaModerada')" @click:clear="borrarDocumento('cuotaModerada')">
                </v-file-input>
              </validation-provider>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="1" style="margin: 0;" class="d-flex align-center pa-1">
          <v-row class="d-flex justify-center">
            <v-col v-if="documentos.cuotaModerada !== '' && documentos.cuotaModerada !== null" cols="1" md="1" class="text-center d-flex pa-0">
              <v-tooltip left color="#0d47a1">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon @click="borrarDocumentoIcono('cuotaModerada')">
                    <v-icon medium color="error">delete</v-icon>
                  </v-btn>
                  <v-btn icon @mousedown.prevent="abrirDocumento('cuotaModerada')" v-bind="attrs" v-on="on">
                    <v-icon medium color="#0d47a1">visibility</v-icon>
                  </v-btn>
                </template>
                <span>Ver comprobante</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
      </v-row> -->

      <!-- Campo para elegir el tipo de entrega -->
      <v-row v-if="solicitudPendiente === false" class="ma-0 mb-5">
        <v-col cols="12" md="11" style="margin: 0;" class="pa-1">
          <v-row class="ma-0">
            <v-col cols="12" md="3" class="d-flex align-center justify-start pa-0">
              <span style="color: #0d47a1;">Tipo de solicitud <span style="color: red">*</span></span>
            </v-col>
            <v-col cols="12" md="9" class="pa-0">
              <v-radio-group v-model="tipoSolicitud" row hide-details class="pa-0 ma-0 radio-container">
                <v-radio v-for="(solicitud, index) in filteredSolicitudes" :key="index" :value="solicitud.value">
                  <template v-slot:label>
                    <div>
                      <div style="color: black; font-weight: bold">
                        {{ solicitud.label }}
                      </div>
                      <div style="font-size: smaller; color: black;">
                        {{ solicitud.descripcion }}
                      </div>
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
              <!-- <v-radio-group v-model="tipoSolicitud" row hide-details class="pa-0 ma-0 radio-container">
                <v-radio :value="solicitudes[0].value">
                  <template v-slot:label>
                    <div>
                      <div style="color: black; font-weight: bold">
                        {{  solicitudes[0].label }}
                      </div>
                      <div style="font-size: smaller; color: black;">
                        {{ solicitudes[0].descripcion }}
                      </div>
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="4" class="pa-0">
              <v-radio-group v-model="tipoSolicitud" row hide-details class="pa-0 ma-0 radio-container">
                <v-radio :value="solicitudes[1].value">
                  <template v-slot:label>
                    <div>
                      <div style="color: black; font-weight: bold">
                        {{  solicitudes[1].label }}
                      </div>
                      <div style="font-size: smaller; color: black;">
                        {{ solicitudes[1].descripcion }}
                      </div>
                    </div>
                  </template>
                </v-radio>
              </v-radio-group> -->
            </v-col>
              <!-- <validation-provider v-slot="{ errors }" :rules="{ obligatorio: true }">
                <v-select v-model="tipoSolicitud" :items="tiposDeSolicitud" outlined dense 
                  :error-messages="errors" :menu-props="{offsetY:true,}">
                </v-select>
              </validation-provider> -->
          </v-row>
        </v-col>
        <!-- <v-col cols="12" md="1" style="margin: 0;" class="d-flex align-center pa-1">
          <v-row class="d-flex justify-center">
            <v-col cols="1" md="1" class="text-center pa-0">
              <v-tooltip left color="#0d47a1">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon @mousedown.prevent="infoTiposEntrega = true" v-bind="attrs" v-on="on">
                    <v-icon medium color="#0d47a1">info</v-icon>
                  </v-btn>
                </template>
                <span>Tipos de entrega</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col> -->
      </v-row>

      <!-- Botón solicitar de entrega programada -->
      <v-row v-if="tipoSolicitud === 'SCHEDULED_DELIVERY' && solicitudPendiente === false" class="ma-0 mb-5">
        <v-col cols="12" md="12" class="pa-0">
          <div class="d-flex justify-center">
            <span class="mb-0">Nota: Verifique los documentos antes de realizar la solicitud.</span>
          </div>
          <div class="d-flex justify-center mt-1">
            <v-btn v-if="documentos.autorizacionMedica === null || documentos.autorizacionMedica === ''" class="boton-actualizar" @click="comprobarAutorizacion">Solicitar</v-btn>
            <v-btn v-else class="boton-actualizar" @click="enviarDocumentos">Solicitar</v-btn>
          </div>
          <div v-if="sinDocumentosRequeridos === true">
            <span class="sinFormula">
              {{ this.documentosRequeridos }}
            </span>
          </div>
        </v-col>
      </v-row>

      <!-- Contenedor para solicitar una entrega a domicilio -->
      <v-container v-if="tipoSolicitud === 'HOME_DELIVERY' && solicitudPendiente === false" style="padding: 0" ref="domicilioRef">
        <v-row class="ma-0">
          <v-col cols="12" md="12" class="pa-0">
            <div class="d-flex justify-center">
              <span class="tituloDatos mb-0">Dirección de entrega</span>
            </div>
          </v-col>
        </v-row>
        <v-row style="margin: 0;" class="pr-">
          <v-col cols="11" sm="6" md="2" class="pa-0 pr-2 ma-0">
            <validation-provider v-slot="{ errors }" :rules="{ required: true }">
              <v-autocomplete ref="municipioDomicilio" id="municipio" v-model="datosDomicilio.municipio" label="Municipio" :error-messages="errors" 
                :items="opcionesMunicipioDomicilio" @click="listarMunicipioDomicilio()" style="width: 100%" dense outlined 
                :menu-props="menuPropsMunDire" @change="updateNombreMunicipioDomicilio" @focus="fieldSize()" no-data-text="Sin resultados">
              </v-autocomplete>
            </validation-provider>
          </v-col>
          <v-col cols="11" sm="6" md="5" class="pa-0 pr-2 ma-0">
            <validation-provider v-slot="{ errors }" :rules="{ required: true }">
              <v-text-field id="direccion" v-model="datosDomicilio.direccion" :error-messages="errors"
                outlined dense label="Dirección *" @click="direccionDomicilio()" readonly>
              </v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="11" sm="6" md="2" class="pa-0 pr-2 ma-0">
            <validation-provider v-slot="{ errors }" :rules="{ required: datosDomicilio.celular === '' || datosDomicilio.celular === null, numeric: true, telefono: 10, regex: '^(601|602|604|605|606|607|608)\\d{7}$' }">
              <v-text-field id="telefonoFijo" v-model="datosDomicilio.telefonoFijo" dense outlined label="Teléfono fijo" :counter="10"
                :error-messages="errors" maxlength="10" @keypress="validarLetra" @paste.prevent>
              </v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="11" sm="6" md="2" class="pa-0 pr-2 ma-0">
            <validation-provider v-slot="{ errors }" :rules="{ required: datosDomicilio.telefonoFijo === '' || datosDomicilio.telefonoFijo === null, numeric: true, telefono: 10, regex: '^(30|31|32|33|34|35|36|37|38|39)\\d{8}$' }">
              <v-text-field id="celular" v-model="datosDomicilio.celular" dense outlined label="Número de celular *" :counter="10"
                :error-messages="errors" maxlength="10" @keypress="validarLetra" @paste.prevent>
              </v-text-field>
            </validation-provider>
          </v-col>
        </v-row>

        <!-- <v-row style="margin: 0;">
          <v-col cols="12" md="11" class="pa-1">
            <v-row class="ma-0">
              <v-col cols="12" md="3" class="d-flex align-center justify-start pa-0">
                <span style="color: #0d47a1;">¿Su fórmula incluye pañales? <span style="color: red;">*</span></span>
              </v-col>
              <v-col cols="12" md="2" class="pa-0">
                <v-radio-group v-model="opcionPanal" row style="margin: 0;" class="d-flex align-center" hide-details>
                  <v-radio label="Si" value="true"></v-radio>
                  <v-radio label="No" value="false"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col v-if="tarifaEncontrada === true" cols="12" md="7" class="d-flex align-left justify-start pa-0 font-weight-bold">
                <span style="color: #0d47a1;">Valor del domicilio: <span style="color: black;">{{ valorTarifa }}</span></span>
              </v-col>
            </v-row>
          </v-col>
        </v-row> -->

        <!-- Tarifa del domicilio-->
        <!-- <v-row v-if="tarifaEncontrada === true" style="margin: 0;">
          <v-col cols="12" md="3" class="pa-1">
            <v-card class="elevation-5" id="cardDownload" ref="cardDownload" style="background-color: white;">
              <v-card-text style="background-color: white;">
                <v-row no-gutters>
                  <v-col cols="12" md="12" align="left">
                    <v-card tile class="elevation-0">
                      <div style="color: black; background-color: white;">Cuenta: <span class="font-weight-black" style="background-color: white;"></span></div>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="12" align="left">
                    <v-card tile class="elevation-0">
                      <div style="color: black; background-color: white;">Valor a pagar: <span class="font-weight-black" style="background-color: white;">{{ valorTarifa }}</span></div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row> -->
        
        <!-- Subir documento - Comprobante de pago a domicilio -->
        <!-- <v-row class="ma-0">
          <v-col cols="12" md="11" style="margin: 0;" class="pa-1">
            <v-row class="ma-0">
              <v-col cols="12" md="3" class="d-flex align-center justify-start pa-0">
                <span style="color: #0d47a1;">Comprobante de pago <span style="color: red">*</span></span>
              </v-col>
              <v-col cols="12" md="9" style="height: 4rem" class="pl-0 pr-0">
                <validation-provider v-slot="{ errors }" class="mr-4">
                  <v-file-input class="file" accept=".pdf, .jpg, .png, .jpeg, .tiff," ref="pagoDomicilioFile" prefix="Seleccionar archivo" prepend-icon :clearable="true" :truncate-length="sizeText"
                    outlined dense :error-messages="formatoIncorrecto.pagoDomicilio || errors" @change="cargarDocumento('pagoDomicilio')" @click:clear="borrarDocumento('pagoDomicilio')">
                  </v-file-input>
                </validation-provider>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="1" style="margin: 0;" class="d-flex align-center pa-1">
            <v-row class="d-flex justify-center">
              <v-col v-if="documentos.pagoDomicilio !== '' && documentos.pagoDomicilio !== null" cols="1" md="1" class="text-center d-flex pa-0">
                <v-tooltip v-if="!screenSmaller" left color="#0d47a1">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon @click="borrarDocumentoIcono('pagoDomicilio')">
                      <v-icon medium color="error">delete</v-icon>
                    </v-btn>
                    <v-btn icon @mousedown.prevent="abrirDocumento('pagoDomicilio')" v-bind="attrs" v-on="on">
                      <v-icon medium color="#0d47a1">visibility</v-icon>
                    </v-btn>
                  </template>
                  <span>Ver comprobante</span>
                </v-tooltip>
                <div v-else>
                  <v-btn icon @click="borrarDocumentoIcono('pagoDomicilio')">
                    <v-icon medium color="error">delete</v-icon>
                  </v-btn>
                  <v-btn icon @click="abrirDocumento('pagoDomicilio')">
                    <v-icon medium color="#0d47a1">visibility</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row> -->

        <v-row v-if="solicitudPendiente === false" class="ma-0 mb-5">
          <v-col cols="12" md="12" class="pa-0" align="center">
            <div>
              <span class="mb-0">Nota: Verifique los documentos antes de realizar la solicitud. </span><br>
              <v-alert class="ma-0 mb-1 pa-1 d-inline-block" color="primary" transition="scale-transition" outlined>
                <span>No se dejarán medicamentos en porterías, unidades, tiendas u oficinas.</span>
              </v-alert>
            </div>
            <div class="d-flex justify-center mt-1">
              <v-btn v-if="documentos.autorizacionMedica === null || documentos.autorizacionMedica === ''" class="boton-actualizar" @click="comprobarAutorizacion">Solicitar</v-btn>
              <v-btn v-else class="boton-actualizar" @click="enviarDocumentos">Solicitar</v-btn>
            </div>
            <div v-if="sinDocumentosRequeridos === true">
              <span class="sinFormula">
                {{ this.documentosRequeridos }}
              </span>
            </div>
          </v-col>
        </v-row>
      </v-container>
      
    </v-container>

    <!-- Dialogo para ver el mensaje de creación de solicitud-->
    <v-dialog v-model="solicitudAprovada" max-width="40rem" scrollable persistent>
      <v-card>
        <div class="text-end pt-1" style="width: 99%;">
          <v-icon color="#0d47a1" @click="cerrarDialogoSolicitudAprovada">close</v-icon>
        </div>
        <v-card-text>
          
          <div class="d-flex text-center">
            <span v-if="estadoRegistro === false" class="mb-0" style="color: black;">Su solicitud fue realizada correctamente e identificada con el número 
              <strong> {{ numeroSolicitud }}, </strong> con el cual podrá hacer seguimiento del trámite en la opción historial.
              Una vez su solicitud sea revisada, el número de pedido podría tener un cambio.
              <span v-if="idAseguradora === 34 && tipoSolicitud === 'HOME_DELIVERY'">
                Recuerda que los medicamentos no se podrán dejar en porterías, unidades, tiendas u oficinas.
              </span>
            </span>
            <span v-else class="mb-0" style="color: black;">Su solicitud fue realizada correctamente e identificada con el número 
              <strong> {{ numeroSolicitud }}, </strong> con el cual podrá hacer seguimiento del trámite en la opción historial.
              <span v-if="idAseguradora === 34 && tipoSolicitud === 'HOME_DELIVERY'">
                Recuerda que los medicamentos no se podrán dejar en porterías, unidades, tiendas u oficinas.
              </span>
            </span>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Diálogo de para ver el documento subido -->
    <v-dialog ref="dialogDocumentos" v-model="mostrarModal" max-width="40rem" scrollable persistent>
      <v-card class="documentos">
        <v-card-title>{{ modalTitulo }}</v-card-title>
        <v-card-text>
          <template v-if="componenteDocumento === 'imagen'">
            <img v-if="extension !== 'tiff'" :src="documentoSeleccionado" width="100%">
            <canvas v-else ref="tiffCanvas" width="100%"></canvas>
          </template>
          <template v-else>
            <pdf :src="documentoSeleccionado" :page="1" :show-all="false" width="100%"></pdf>
          </template>
          <!-- <pdf :src="documentoSeleccionado" :page="1" :show-all="false" width="100%"></pdf> -->
        </v-card-text>
      
        <v-card-actions class="justify-end">
          <v-btn v-if="visibility === false" class="boton-actualizar mb-4" @click="cerrarModal">Cancelar</v-btn>
          <v-btn v-if="visibility === false" class="boton-actualizar mb-4 me-2" @click="confirmarDocumento">Confirmar</v-btn>
          <v-btn v-if="visibility === true" class="boton-actualizar mb-2 me-2" @click="mostrarModal = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Diálogo de para ver el documento de identificación ya existente en base de datos-->
    <v-dialog v-model="abrirDocumentoIdentidad" max-width="40rem" scrollable persistent>
      <v-card>
        <v-card-title>{{ modalDocumentoDigital }}</v-card-title>
        <v-card-text>
          <pdf :src="pdfBase64" :page="1" v-if="mostarDocumento" :key="pdfBase64"></pdf>
        </v-card-text>
      
        <v-card-actions class="justify-end">
          <v-btn class="boton-actualizar mb-2 me-2" @click="abrirDocumentoIdentidad = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Diálogo de información de los tipos de entrega -->
    <v-dialog v-model="infoTiposEntrega" max-width="30rem" scrollable close-delay persistent>
      <v-card>
        <v-card-text class="mt-4">
          <div>
            <p style="color: black;" class="ma-0">
              <span><strong>Entrega Programada: </strong></span>
              El usuario deberá dirigirse al centro de atención en la fecha que se le indique cuando la solicitud sea aprobada.
              <br>
              <br>
              <span><strong>Entrega a domicilio: </strong></span>
              Los medicamentos seran enviados a la dirección indicada para tu comodidad. 
              Ten en cuenta que este servicio tiene un costo adicional y se debe hacer el pago antes de la solicitud.
            </p>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-3 mr-3">
          <v-btn rounded @click="infoTiposEntrega = false" color="primary">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialogo para editar los datos del afiliado -->
    <v-dialog v-model="dialog" persistent transition="dialog-bottom-transition" max-width="33.5rem">
      <v-card>
        <v-card-title class="fondoDialog">
          <span class="text-h6">Edición de datos</span>
        </v-card-title>

        <v-card-text style="padding-top: 0.7rem;">
          <validation-observer ref="observer" v-slot="{ invalid }">
            <v-form class="mt-3" lazy-validation>
                
              <div class="d-flex">
                <!-- Campo para el departamento donde se ubica el afiliado -->
                <validation-provider v-slot="{ errors }" :rules="{ required: true }" class="me-2" style="width: 50%">
                  <v-autocomplete id="departamento" v-model="datosAfiliado.departamento" label="Departamento" 
                    dense outlined :error-messages="errors" :items="opcionesDepartamento" 
                    @click="listarDepartamento()" :menu-props="{ maxHeight: 170, offsetY: true, maxWidth: 240 }" >
                  </v-autocomplete>
                </validation-provider>
                <!-- Campo para la municipio donde se ubica el afiliado -->
                <validation-provider v-slot="{ errors }" :rules="{ required: datosAfiliado.departamento !== '' && datosAfiliado.departamento !== '00' }" style="width: 50%">
                  <v-autocomplete id="municipio" v-model="datosAfiliado.municipio" label="Municipio" dense outlined :error-messages="errors" 
                    :items="opcionesMunicipio" @click="listarMunicipio()" :menu-props="{ maxHeight: 170, offsetY: true, maxWidth: 240 }"
                    :disabled="datosAfiliado.departamento === '' || datosAfiliado.departamento === '00'" @change="updateNombreMunicipio">
                  </v-autocomplete>
                </validation-provider>
              </div>
              <div class="d-flex">
                <!-- Campo para la dirección del afiliado -->
                <validation-provider v-slot="{ errors }" :rules="{ required: true }" style="width: 100%">
                  <v-text-field id="direccion" v-model="datosAfiliado.direccion" :error-messages="errors"
                    outlined dense label="Dirección" @click="abrirDialogoDireccion()" readonly>
                  </v-text-field>
                </validation-provider>
              </div>
              <div class="d-flex">
                <!-- Campo para el correo del afiliado -->
                <validation-provider v-slot="{ errors }" :rules="{ required: true, validEmail: true}" style="width: 100%">
                  <v-text-field id="correo" v-model="datosAfiliado.correo" dense outlined label="Correo"
                    :error-messages="errors">
                  </v-text-field>
                </validation-provider>
              </div>
              <div class="d-flex">
                <!-- Campo para el número telefonico del afiliado -->
                <validation-provider v-slot="{ errors }" :rules="{ obligatorio: datosAfiliado.celular === '' || datosAfiliado.celular === null, numeric: true, telefono: 10, regex: '^(601|602|604|605|606|607|608)\\d{7}$' }" class="me-2" style="width: 50%">
                  <v-text-field id="telefonoFijo" v-model="datosAfiliado.telefonoFijo" dense outlined label="Teléfono fijo" :counter="10"
                    :error-messages="errors" maxlength="10" @keypress="validarLetra" @paste.prevent>
                  </v-text-field>
                </validation-provider>
                <!-- Campo para el número de celular del afiliado -->
                <validation-provider v-slot="{ errors }" :rules="{ obligatorio: datosAfiliado.telefonoFijo === '' || datosAfiliado.telefonoFijo === null, numeric: true, telefono: 10, regex: '^(30|31|32|33|34|35|36|37|38|39)\\d{8}$' }" style="width: 50%">
                  <v-text-field id="celular" v-model="datosAfiliado.celular" dense outlined label="Número de celular" :counter="10"
                    :error-messages="errors" maxlength="10" @keypress="validarLetra" @paste.prevent>
                  </v-text-field>
                </validation-provider>
              </div>
              <!-- Botones de cerrar y actualizar que se activan cuando se abre el dialogo para los datos del afiliado -->
              <div class="d-flex justify-end">
                <v-btn color="#0d47a1" class="mr-2" rounded outlined @click="clean()">
                  CANCELAR
                </v-btn>
                <v-btn class="boton-actualizar" depressed :disabled="invalid" @click="actualizar()">
                  ACTUALIZAR
                </v-btn>
              </div>
            </v-form>
          </validation-observer>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- dialogo para cambiar la contraseña del afiliado -->
    <v-dialog v-model="dialogContrasena" persistent transition="dialog-bottom-transition" max-width="19rem">
      <v-card>
        <v-card-title class="fondoDialog">
          <span class="text-h6">Cambio de contraseña</span>
        </v-card-title>

        <v-card-text style="padding-top: 0.7rem;">
          <validation-observer ref="observerContrasena" v-slot="{ invalid }">
            <v-form class="mt-3" lazy-validation>
                
              <div>
                <v-tooltip right color="#1867c0" v-model="tooltip">
                  <template v-slot:activator="{ attrs }">
                    <validation-provider v-slot="{ errors }" :rules="{ required: true, min: 6 }" >
                      <v-text-field v-model="contrasena" label="Nueva contraseña" maxlength="6" dense outlined @paste.prevent :counter="6"
                        v-bind="attrs" :type="show2 ? 'text' : 'password'" :append-icon="show2 ? 'visibility' : 'visibility_off'"
                        @click:append="show2 = !show2" required :error-messages="errors" @keydown.space.prevent @keypress="validarLetra"
                        @click="tooltip = !tooltip" @focus="tooltip = false" @blur="tooltip = false">
                      </v-text-field>
                    </validation-provider>
                  </template>
                  <span>
                    La contraseña debe estar <br> compuesta por 6 números.
                  </span>
                </v-tooltip>
              </div>                         
              <div>
                <validation-provider v-slot="{ errors }" :rules="{ required: true, contrasenaValida: true }" ref="confirmarContrasena">
                  <v-text-field v-model="confirmarContrasena" label="Confirmar contraseña" @click="tooltip = false"
                    :type="show3 ? 'text' : 'password'" :append-icon="show3 ? 'visibility' : 'visibility_off'" maxlength="6" @keypress="validarLetra" :counter="6"
                    @click:append="show3 = !show3" required dense outlined :error-messages="errors" @keydown.space.prevent :disabled="contrasena === '' || contrasena === null">
                  </v-text-field>
                </validation-provider>
              </div>
              <div class="d-flex justify-space-between">
                <v-btn color="#0d47a1" rounded outlined @click="resetContrasena()">
                  Cancelar 
                </v-btn>
                <v-btn class="boton-actualizar" depressed :disabled="invalid" @click="guardarContraseña()">
                  Confirmar 
                </v-btn>
              </div>
            </v-form>
          </validation-observer>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Mensaje de alerta para cuando el cambio de contraseña es correcto -->
    <template>
      <div v-if="alertaPassword" class="overlay">
        <v-alert v-model="alertaPassword" class="notificationValidation" persistent transition="dialog-bottom-transition">
          <v-icon class="d-flex justify-center" color="white" large>task_alt</v-icon>
          <span class="d-flex justify-center">{{ mensajeContrasena }}</span>
        </v-alert>
      </div>
    </template>

    <!-- Mensaje de alerta para cuando hay una solicitud en proceso -->
    <template>
      <div v-if="validateProgress" class="overlay">
        <v-alert class="notificationValidation" persistent transition="dialog-bottom-transition">
          <v-icon class="d-flex justify-center rotate-animation" color="white" large>rotate_right</v-icon>
          <span class="d-flex justify-center">Solicitud en proceso</span>
        </v-alert>
      </div>
    </template>

    <!-- Mensaje de alerta para cuando el cambio de dirección del afiliado es correcto -->
    <template>
      <div v-if="alertaDatos" class="overlay">
        <v-alert v-model="alertaDatos" class="notificationValidation" persistent transition="dialog-bottom-transition">
          <v-icon class="d-flex justify-center" color="white" large>task_alt</v-icon>
          <span class="d-flex justify-center">{{ mensajeDatos }}</span>
        </v-alert>
      </div>
    </template>

    <!-- Diálogo para recordar la importancia de la autorización médica -->
    <v-dialog v-model="infoAutorizacionMedica" max-width="22rem" scrollable close-delay persistent>
      <v-card>
        <v-card-text class="mt-4">
          <div>
            <p style="color: black;" class="ma-0 text-center">
              Si su atención generó una autorización, recuerde adjuntarla.
            </p>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pb-3">
          <v-btn color="#0d47a1" small rounded outlined @click="infoAutorizacionMedica = false">Si</v-btn>
          <v-btn rounded small @click="enviarDocumentos" color="primary">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Contenedor para mostrar los mensajes de error -->
    <div v-if="mostrarNotificacion" :class="['notificacion', tipoNotificacion]">
      <span><v-icon :color="tipoNotificacionColor" :class="iconoNotificacionClase" class="rotate-animation-notification size">{{ iconoNotificacion }}</v-icon></span>
      <span>{{ mensajeNotificacion }}</span>
    </div>

  </v-main>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapMutations, mapState } from 'vuex';
import { Role } from '@/router/role.js';
import { required, min, email, numeric, regex } from 'vee-validate/dist/rules';
import pdf from 'vue-pdf';
import AddressForm from '../../../../components/AddressForm.vue';
import Tiff from 'tiff.js';

extend('required', {
  ...required,
  message: 'Este campo es requerido'
});
extend('obligatorio', {
  ...required,
  message: 'Campo obligatorio'
});
extend('min', {
  ...min,
  message: 'Requiere 6 dígitos.'
});
extend('validEmail', {
  ...email,
  message: 'La dirección de correo debe ser válida'
});
extend('telefono', {
  ...min,
  message: 'Requiere 10 dígitos.'
});
extend('numeric', {
  ...numeric,
  message: 'Este campo solo permite números'
});
extend('regex', {
  ...regex,
  message: 'Formato Incorrecto',
});

export default {
  name: 'Entrega',
  components: {
    pdf,
    ValidationObserver,
    ValidationProvider,
    AddressForm,
  },

  data() {
    return {
      nombres: '',
      apellidos: '',
      identificacion: '',
      tipoId: '',
      idPais: '',
      idDepartamento: '',
      idCiudad: '',
      departamento: '',
      ciudad: '',
      direccion: '',
      correo: '',
      telefonoFijo: '',
      celular: '',
      idKeycloack: '',
      idAseguradora: null,
      idEmpresa: 1,
      idAfiliado: null,
      documentoActual: null,
      barrioActual: '',
      idBarrio: null,
      primerNombre: '',
      segundoNombre: '',
      primerApellido: '',
      segundoApellido: '',
      estadoRegistro: false,
      nombreMunicipio: '',
      nombreMunicipioDomicilio: '',
      documentos: {
        documento: null,
        formulaMedica: null,
        autorizacionMedica: null,
        cuotaModerada: null,
        pagoDomicilio: null,
      },
      documentosTemporales: {
        documento: null,
        formulaMedica: null,
        autorizacionMedica: null,
        cuotaModerada: null,
        pagoDomicilio: null,
      },
      formatoIncorrecto: {
        documento: '',
        formulaMedica: '',
        autorizacionMedica: '',
        cuotaModerada: '',
        pagoDomicilio: '',
      },
      mostrarModal: false,
      modalTitulo: '',
      documentoSeleccionado: null,
      tipoDocumentoActual: '',
      documentosRequeridos: '',
      sinDocumentosRequeridos: false,
      componenteDocumento: null,
      dialog: false,
      datosAfiliado: {
        nombres: '',
        apellidos: '',
        identificacion: '',
        pais: '',
        barrio: '',
        departamento: '',
        municipio: '',
        direccion: '',
        correo: '',
        telefonoFijo: '',
        celular: '',
      },
      opcionesDepartamento: [],
      opcionesMunicipio: [],
      opcionesMunicipioDomicilio: [],
      barrioSelecionado: '',
      rules: {
        agregarRules: [
          v => !!v || "Campo obligatorio",
        ],
        direccion: [
          v => !!v || "Obligatorio",
        ],
      },
      camposDireccionUrbanaLlenos: false,
      camposDireccionRuralLlenos: false,
      dialogContrasena: false,
      contrasena: '',
      confirmarContrasena: '',
      show2: false,
      show3: false,
      mensajeContrasena: '',
      alertaPassword: false,
      mensajeDatos: '',
      alertaDatos: false,
      visibility: false,
      tipoSolicitud: null,
      tiposDeSolicitud: [
        { text: "Entrega programada", value: "SCHEDULED_DELIVERY" },
        { text: "Entrega a domicilio", value: "HOME_DELIVERY" }
      ],
      infoTiposEntrega: false,
      opcionPanal: null,
      datosDomicilio: {
        direccion: '',
        telefonoFijo: '',
        celular: '',
        municipio: '',
      },
      esDireccionDomicilio: false,
      numeroSolicitud: '',
      solicitudAprovada: false,
      roles: Role,
      userRoles: {},
      usuario: '',
      mostrarNotificacion: false,
      mensajeNotificacion: "",
      tipoNotificacion: "",
      tipoNotificacionColor: "",
      iconoNotificacion: "",
      iconoNotificacionClase: "",
      solicitudPendiente: null,
      mensajeSolicitudPendiente: '',
      tI: null,
      nI: null,
      abrirDocumentoIdentidad: false,
      mostarDocumento: false,
      modalDocumentoDigital: '',
      pdfBase64: null,
      direccionLista: '',
      infoAutorizacionMedica: false,
      tarifaEncontrada: false,
      valorTarifa: null,
      telefonoSolicitud: null,
      celularSolicitud: null,
      bodegaBuscada: null,
      tooltip: false,
      nombreFinal: '',
      direccionFija: '',
      detalleDireccion: '',
      solicitudes: [
        {
          label: 'Entrega programada',
          value: 'SCHEDULED_DELIVERY',
          descripcion: 'El usuario deberá dirigirse al centro de atención en la fecha que se le indique cuando la solicitud sea aprobada.'
        },
        {
          label: 'Entrega a domicilio',
          value: 'HOME_DELIVERY',
          descripcion: 'Los medicamentos serán enviados a la dirección indicada para tu comodidad. Ten en cuenta que este servicio tiene un costo adicional.'
        }
      ],
      datoAdicionalDireccion: '',
      screenSmaller: false,
      sizeText: null,
      tipoDirecionDelpaciente: null,
      validateProgress: false,
      menuPropsMunDire: { offsetY: true, maxHeight: 180, maxWidth: null },
      dialogDireccion: false,
      tiposDireccion: '',
      tipoDireccion: '',
      tiposVias: ["AVENIDA CALLE", "AVENIDA CARRERA", "AUTOPISTA", "AVENIDA", "ANILLO VIAL", "CALLE", "CALLEJÓN", "CARRERA", "CARRETERA", "CIRCUNVALAR", "DIAGONAL", "TRANSVERSAL"],
      barrio: '',
      opcionesBarrio: [],
      nombreVereda: '',
      barrioOpcional: '',
      formularioDireccion: {
        component: 'AddressForm',
        title: 'Dirección',
      },
      componenteAddress: false,
      direccionSelecionada: '',
      municipioSeleccionado: '',
      solicitudesEnProceso: null,
      mensajePrincipal: '',
      extension: '',
    }
  },

  // Se inicializa la variable userRoles con los roles del usuario autenticado
  created() {
    this.usuario = this.auth.username.toUpperCase();
    this.userRoles = this.auth.roles;
    this.tI = this.$store.state.tipoIdentificacion;
    this.nI = this.$store.state.numeroIdentificacion;
    this.listarDatosAfiliado();
  },

  computed: {
    ...mapState(["auth", "enterprise", "tipoIdentificacion", "numeroIdentificacion"]),
    'nombreDocumento.documento': {
      get() {
        return this.nombreDocumento.documento;
      },
      set(value) {
        this.nombreDocumento.documento = value;
      },
    },
    partesProcesadas() {
      if (this.mensajePrincipal !== '') {
        return this.procesarMensaje(this.mensajeSolicitudPendiente);
      }
      return [];
    },
    filteredSolicitudes() {
      // Si idAseguradora es 34, solo muestra "Entrega a domicilio"
      return this.idAseguradora === 34 
        ? this.solicitudes.filter(solicitud => solicitud.value === 'HOME_DELIVERY') 
        : this.solicitudes;
    },
  },

  watch: {
    'datosAfiliado.departamento': {
      handler: 'onDepartamentoChange',
      deep: true,
    },
    // Observador para cambiar la opción de pañal cuando el tipo de solicitud cambia a entrega programada
    tipoSolicitud(newValue) {
      if (newValue === 'SCHEDULED_DELIVERY') {
        this.opcionPanal = null;
        this.documentos.pagoDomicilio = null;
        this.formatoIncorrecto.pagoDomicilio = "";
      }
    },
    // Observador para la contraseña del usuario
    'contrasena': function (contrasena) {
      this.contrasenaActual = contrasena;
      // Validación personalizada de la contraseña
      extend('contrasenaValida', {
        validate: (value) => value === this.contrasenaActual,
        message: 'Las contraseñas no coinciden'
      });
      // Reinicio del campo de confirmación de contraseña si no coincide con la contraseña actual
      if (contrasena !== this.confirmarContrasena ) {
        this.confirmarContrasena = '';
        const confirmarContrasena = this.$refs.confirmarContrasena;
        if (confirmarContrasena) {
          confirmarContrasena.reset();
        }
      }
    },
    opcionPanal: {
      handler: 'tarifa',
    },
    mostrarModal(newValue) {
      if (newValue === false) {
        this.goTo();
      }
    },
  },

  mounted() {
    this.usuario = this.auth.username.toUpperCase();
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },

  methods: {
    ...mapMutations(["updateAuth", "hideNotify", "showSuccess", "showBusy", "hideBusy",]),
    /**
     * Recupera la tarifa del domicilio asociada a la ciudad actual y actualiza el estado del componente con el valor de la tarifa.
     * 
     * En caso de error durante la solicitud al servidor, se maneja el error utilizando el método 'observarError'.
     */
    async tarifa() {
      this.tarifaEncontrada = false;
      this.valorTarifa = null;
      if (this.opcionPanal !== null) {
        try {
          const response = await this.$http.get(`msa-external/api/tarifa/${this.idCiudad}`);
          if (this.opcionPanal === 'false') {
            this.valorTarifa = response.data.tarifa_sobre.toLocaleString('es-CO', {
              style: 'currency',
              currency: 'COP',
              minimumFractionDigits: 0
            });
          } else {
            this.valorTarifa = response.data.tarifa_panal.toLocaleString('es-CO', {
              style: 'currency',
              currency: 'COP',
              minimumFractionDigits: 0
            });
          }
          this.tarifaEncontrada = true;
        } catch (error) {
          this.observarError(error);
        }
      }
    },
    /**
     * Realiza una solicitud al servidor para obtener y listar los datos del afiliado asociados a un tipo y número de identificación.
     * 
     * Recupera los datos del afiliado del servidor y los asigna a las variables correspondientes en la instancia del componente.
     * Si no se encuentran datos del afiliado, no se realiza ninguna asignación y se continúa con la ejecución del código.
     * 
     * En caso de error durante la solicitud al servidor, se maneja el error utilizando el método 'observarError'.
     */
    async listarDatosAfiliado() {
      const tipoIdentificacion = this.tI;
      const numeroIdentificacion = this.nI;
      try {
        const response = await this.$http.get(`msa-external/api/registro/${tipoIdentificacion}/${numeroIdentificacion}`);
        if (response.data !== "") {
          this.$store.commit('setIdRegistro', response.data.idRegistro);
          this.idAfiliado = response.data.idRegistro;
          this.nombres = `${response.data.primerNombre ?? ''} ${response.data.segundoNombre ?? ''}`.trim();
          this.primerNombre = response.data.primerNombre !== '' ? response.data.primerNombre : '';
          this.segundoNombre = response.data.segundoNombre !== '' ? response.data.segundoNombre : '';
          this.primerApellido = response.data.primerApellido !== '' ? response.data.primerApellido : '';
          this.segundoApellido = response.data.segundoApellido !== '' ? response.data.segundoApellido : '';
          this.apellidos = `${response.data.primerApellido ?? ''} ${response.data.segundoApellido ?? ''}`.trim();
          this.identificacion = response.data.numeroDocumento;
          this.tipoId = response.data.tipoDocumento.descripcion;
          this.idPais = response.data.pais.idPais;
          this.idDepartamento = response.data.departamento.idDepartamento;
          this.idCiudad = response.data.ciudad.idCiudad;
          this.departamento = response.data.departamento.departamento;
          this.ciudad = response.data.ciudad.ciudad;
          this.direccion = response.data.direccion;
          const esDireccionUrbana = this.tiposVias.some(opcion => this.direccion.startsWith(opcion));
          if (esDireccionUrbana) {
            this.tipoDirecionDelpaciente = true;
          } else {
            this.tipoDirecionDelpaciente = false;
          }
          this.correo = response.data.email;
          this.telefonoFijo = response.data.numeroTelefono != 0 ? response.data.numeroTelefono : '';
          this.celular = response.data.numeroCelular;
          this.idKeycloack = response.data.idKeycloak;
          this.idAseguradora = response.data.aseguradora.idAseguradora;
          if (this.idAseguradora === 34) this.tipoSolicitud = 'HOME_DELIVERY';
          this.documentoActual = response.data.documentoDigital != null ? response.data.documentoDigital : null;
          this.documentos.documento = this.documentoActual;
          this.barrioActual = response.data.barrio != null ? response.data.barrio.idBarrio : '';
          this.estadoRegistro = response.data.estadoRegistro;
          let barrioEnProceso = response.data.barrio != null ? response.data.barrio.barrio : '';
          let direccionCompleta = response.data.direccion;
          const partesDireccion = direccionCompleta.split(',');
          if (partesDireccion.length > 1) {
            this.direccionFija = partesDireccion[0].trim();
            this.detalleDireccion = partesDireccion.slice(1).join(',').trim();
          } else {
            this.direccionFija = partesDireccion[0].trim();
            this.detalleDireccion = '';
          }
          if (barrioEnProceso === '' && this.detalleDireccion !== '') {
            if (this.detalleDireccion.includes(',')) {
              if (esDireccionUrbana) {
                let primeraParteDetalle = this.detalleDireccion.split(',')[0];
                this.barrioOpcional = primeraParteDetalle.trim();
                this.detalleDireccion = this.detalleDireccion.substring(this.detalleDireccion.indexOf(',') + 1).trim();
              } else {
                const regex = /\b(VEREDA|SECTOR)\b\s*(.*)/i;
                const match = this.direccionFija.match(regex);
                this.barrioOpcional = match[2];
              }
            } else if (esDireccionUrbana) {
              this.barrioOpcional = this.detalleDireccion;
              this.detalleDireccion = '';
            } else {
              const regex = /\b(VEREDA|SECTOR)\b\s*(.*)/i;
              const match = this.direccionFija.match(regex);
              this.barrioOpcional = match[2];
            }
            this.barrioSelecionado = 'OTRO'
          } else if(barrioEnProceso === '' && this.detalleDireccion === '') {
            if (!esDireccionUrbana) {
              const regex = /\b(VEREDA|SECTOR)\b\s*(.*)/i;
              const match = this.direccionFija.match(regex);
              this.barrioOpcional = match[2];
            }
            this.barrioSelecionado = 'OTRO'
          } else {
            this.barrioOpcional = '';
            this.barrioSelecionado = barrioEnProceso;
          }
          this.datosDomicilio.telefonoFijo = response.data.numeroTelefono != 0 ? response.data.numeroTelefono : '';
          this.datosDomicilio.celular = response.data.numeroCelular != 0 ? response.data.numeroCelular : '';
          await this.listarMunicipioDomicilio();
          let municipioActual = this.opcionesMunicipioDomicilio.find(municipio => municipio.municipio === this.ciudad);
          if (typeof municipioActual === 'object') {
            this.datosDomicilio.municipio = municipioActual.value;
            this.nombreMunicipio = municipioActual.text;
            this.nombreMunicipioDomicilio = municipioActual.text;
          } else {
            this.datosDomicilio.municipio = '';
            this.nombreMunicipio = '';
            this.nombreMunicipioDomicilio = '';
          }
          let barrioListo = (this.barrioSelecionado === 'OTRO' && this.barrioOpcional !== '') ? this.barrioOpcional : this.barrioSelecionado;
          if (esDireccionUrbana) {
            this.datosDomicilio.direccion = `${ this.direccionFija }, ${ barrioListo }, ${ this.nombreMunicipioDomicilio }${ this.detalleDireccion !== '' ? ', ' + this.detalleDireccion : ''}`;
          } else {
            this.datosDomicilio.direccion = `${ this.direccionFija }, ${ this.nombreMunicipioDomicilio }${ this.detalleDireccion !== '' ? ', ' + this.detalleDireccion : ''}`;
          }
          this.validarSolicitud();
        }
      } catch (error) {
        this.observarError(error);
      }
    },
    /**
     * Realiza una solicitud al servidor para validar si existe una solicitud de entrega pendiente asociada al afiliado.
     * 
     * Si se encuentra una solicitud de entrega pendiente, se establece la bandera 'solicitudPendiente' como verdadera 
     * y se asigna el mensaje de la solicitud pendiente a la variable 'mensajeSolicitudPendiente'.
     * 
     * En caso de error durante la solicitud al servidor, se maneja el error utilizando el método 'observarError'.
     */
    validarSolicitud() {
      this.solicitudAprovada = false;
      const idAfiliado = this.idAfiliado;
      const endpoint = this.estadoRegistro === true ? 'solicitudEntrega/validar' : 'solicitud-temporal/validar';
      this.$http.get(`msa-external/api/${endpoint}?idAfiliado=${idAfiliado}`)
      .then( response => {
        const data = response.data.split('||');
        const mensaje = data[0];
        const cantidad = parseInt(data[1], 10);
        if (mensaje !== '') {
          this.solicitudPendiente = true;
          const index = mensaje.indexOf(':');
          if (index !== -1) {
            this.mensajePrincipal = mensaje.substring(0, index + 1).trim();
            this.mensajeSolicitudPendiente = mensaje.substring(index + 1).trim();
          } else {
            this.mensajePrincipal = '';
            this.mensajeSolicitudPendiente = mensaje;
          }
          this.solicitudesEnProceso = cantidad;
        } else {
          this.solicitudPendiente = false;
          this.mensajeSolicitudPendiente = '';
          this.solicitudesEnProceso = null;
        }
      }).catch(error => {
        this.observarError(error);
      })
    },
    /**
     * Le da formato especifico a los mensajes de las solicitudes en proceso.
     * @param mensaje - Mensajes de las solicitudes en proceso
     */
    procesarMensaje(mensaje) {
      const partes = mensaje.split(', ');
      const partesProcesadas = partes.map(parte => {
        const numeroSolicitud = parte.match(/número (\d+)/)[1];
        const estado = parte.match(/estado (\w+)/)[1];
        return {
          numero: `<span>Solicitud <strong>${numeroSolicitud}</strong></span>`,
          estado: `<span>en estado <strong>${estado}</strong>.</span>`
        };
      });
      return partesProcesadas;
    },
    /**
     * Le da formato especifico a un mensaje de solicitud en proceso.
     * @param mensaje - Mensaje de una solicitud en proceso.
     */
    parteProcesada(mensaje) {
      const numero = mensaje.match(/número (\d+)/)[1];
      const estado = mensaje.match(/estado (\w+)/)[1];
      return `Tienes una solicitud con número <strong>${numero}</strong> en estado <strong>${estado}</strong>.`;
    },
    /**
     * Realiza una solicitud al servidor para obtener y mostrar el documento digital asociado al afiliado.
     * Si la solicitud es exitosa, muestra el documento digital asignando el contenido base64 a la variable 'pdfBase64'.
     * 
     * En caso de error durante la solicitud al servidor, se maneja el error utilizando el método 'observarError'.
     */
    verDocumentoDigital() {
      this.mostarDocumento = false;
      this.abrirDocumentoIdentidad = false;
      this.pdfBase64 = null;
      this.modalDocumentoDigital = '';
      const rutaDocumento = `${this.idAfiliado}/${this.documentoActual}`;
      const endpoint = this.estadoRegistro === true ? 'solicitudEntrega/getFileBase64' : 'solicitud-temporal/getFileBase64';
      this.$http.get(`msa-external/api/${endpoint}?fileName=${rutaDocumento}`)
      .then( response => {
          this.modalDocumentoDigital = `Documento de identidad`;
          this.mostarDocumento = true;
          this.abrirDocumentoIdentidad = true;
          this.visibility = true;
          this.pdfBase64 = "data:application/pdf;base64," + response.data;
      }).catch(error => {
        this.observarError(error);
      })
    },
    /**
     * Abre un diálogo para editar los datos del afiliado.
     * Establece valores para cada campo dentro del diálogo.
     */
    async abrirDialog() {
      await this.listarDepartamento();
      let departamentoActual = this.opcionesDepartamento.find(departamento => departamento.value === this.idDepartamento);
      if (typeof departamentoActual === 'object') {
        this.datosAfiliado.departamento = departamentoActual.value;
      } else {
        this.datosAfiliado.departamento = '';
      }
      await this.listarMunicipio();
      let municipioActual = this.opcionesMunicipio.find(municipio => municipio.municipio === this.ciudad);
      if (typeof municipioActual === 'object') {
        this.datosAfiliado.municipio = municipioActual.value;
      } else {
        this.datosAfiliado.municipio = '';
      }
      await this.listarBarrio();
      let barrioActual = this.opcionesBarrio.find(value => value.value === this.barrioSelecionado);
      if (typeof barrioActual === 'object') {
        this.barrio = barrioActual.value;
        this.nombreVereda = barrioActual.value;
      } else {
        this.barrio = null;
        this.nombreVereda = null;
      }
      const esDireccionUrbana = this.tiposVias.some(opcion => this.direccionFija.startsWith(opcion));
      if (esDireccionUrbana) {
        let barrioListo = (this.barrioSelecionado === 'OTRO' && this.barrioOpcional !== '') ? this.barrioOpcional : this.barrioSelecionado;
        this.datosAfiliado.direccion = `${ this.direccionFija }, ${ barrioListo }, ${ municipioActual.text }${ this.detalleDireccion !== '' ? ', ' + this.detalleDireccion : ''}`;
      } else {
        this.datosAfiliado.direccion = `${ this.direccionFija }, ${ municipioActual.text }${ this.detalleDireccion !== '' ? ', ' + this.detalleDireccion : ''}`;
      }
      this.datosAfiliado.correo = this.correo;
      this.datosAfiliado.telefonoFijo = this.telefonoFijo;
      this.datosAfiliado.celular = this.celular;
      this.dialog = true;
    },
    /**
     * Muestra un mensaje de error cuando el formato del archivo es incorrecto.
     */
    mostrarMensajeErrorFormatoIncorrecto(type) {
      this.formatoIncorrecto[type] = 'Formato incorrecto, debe subir un archivo pdf o una imagen .png, .jpg, .jpeg, .tiff';
    },
    /**
     * Carga un documento seleccionado por el usuario y lo muestra en un modal para su confirmación.
     * 
     * @param {string} type - Tipo de documento a cargar ('documento', 'formulaMedica', 'autorizacionMedica', 'cuotaModerada', 'pagoDomicilio').
     */
    cargarDocumento(type) {
      const fileInput = event.target;

      if (fileInput && fileInput.files && fileInput.files[0]) {
        const file = fileInput.files[0];

        const extensionesPermitidas = ['pdf', 'jpg', 'png', 'jpeg', 'tiff'];

        if (!file || !extensionesPermitidas.includes(file.name.split('.').pop().toLowerCase())) {
          this.mostrarMensajeErrorFormatoIncorrecto(type);
          return;
        }
      
        const reader = new FileReader();
        reader.onload = (event) => {
          const base64String = event.target.result;
          if (this.esImagen(base64String) || this.esPDF(base64String)) {
            this.documentosTemporales[type] = reader.result;
            this.tipoDocumentoActual = type;
            this.visibility = false;
            this.mostrarDocumento(type);
            this.formatoIncorrecto[type] = false;
          } else {
            this.mostrarMensajeErrorFormatoIncorrecto(type);
          }
        };
        reader.readAsDataURL(file);
        fileInput.value = null;
      }
    },
    /** Valida que la imagen sea correcta */
    esImagen(base64String) {
      return base64String.startsWith('data:image/jpeg;base64,/9j/') || 
        base64String.startsWith('data:image/jpg;base64,') ||
        base64String.startsWith('data:image/png;base64,iVBORw0KGgo') ||
        base64String.startsWith('data:image/tiff;base64,SUkq');
    },
    /** Valida que el pdf sea correcto */
    esPDF(base64String) {
      if (!base64String.startsWith('data:application/pdf;base64,')) {
        return false;
      }

      const binary = atob(base64String.split(',')[1]);
      const bytes = new Uint8Array(binary.length);
      for (let i = 0; i < binary.length; i++) {
        bytes[i] = binary.charCodeAt(i);
      }

      const pdfText = new TextDecoder().decode(bytes);

      return pdfText.includes('%PDF-');
    },
    /**
     * Confirma el documento cargado y lo asigna al documento correspondiente.
     */
    confirmarDocumento() {
      const tipoDocumento = this.tipoDocumentoActual;
      this.documentos[tipoDocumento] = this.documentosTemporales[tipoDocumento];
      this.mostrarModal = false;
    },
    /**
     * Cierra el modal y restablece el valor del documento temporal en caso de cancelación.
     */
    cerrarModal() {
      const tipoDocumento = this.tipoDocumentoActual;
      this.documentosTemporales[tipoDocumento] = null;
      this.tipoDocumentoActual = '';
      const refDocumento = this.$refs.documentoIdentidadFile;
      const refFormulaMedica = this.$refs.formulaMedicaFile;
      const refAutorizacionMedica = this.$refs.autorizacionMedicaFile;
      const refCuotaModerada = this.$refs.cuotaModeradaFile;
      const refpagoDomicilio = this.$refs.pagoDomicilioFile;

      if (tipoDocumento === 'documento' && refDocumento) {
        refDocumento.reset();
        this.documentos.documento = null;
      } else if (tipoDocumento === 'formulaMedica' && refFormulaMedica) {
        refFormulaMedica.reset();
        this.documentos.formulaMedica = null;
      } else if (tipoDocumento === 'autorizacionMedica' && refAutorizacionMedica) {
        refAutorizacionMedica.reset();
        this.documentos.autorizacionMedica = null;
      } else if (tipoDocumento === 'cuotaModerada' && refCuotaModerada) {
        refCuotaModerada.reset();
        this.documentos.cuotaModerada = null;
      }else if (tipoDocumento === 'pagoDomicilio' && refpagoDomicilio) {
        refpagoDomicilio.reset();
        this.documentos.pagoDomicilio = null;
      }
      this.mostrarModal = false;
    },
    /** Método para restablecer el scroll */
    goTo() {
      this.$vuetify.goTo(
        '.documentos',{
          offset: 100,
          container: '.v-card__text'
        }
      )
    },
    /**
     * Muestra el documento seleccionado en el modal correspondiente.
     * 
     * @param {string} type - Tipo de documento a mostrar ('documento', 'formulaMedica', 'autorizacionMedica', 'cuotaModerada', 'pagoDomicilio').
     */
    mostrarDocumento(type) {
      this.tipoDocumentoActual = type;
      this.modalTitulo = `Verifique que el documento sea legible.`;
      if (this.documentosTemporales[type] != null) {
        this.documentoSeleccionado = this.documentosTemporales[type];
        const extension = this.documentosTemporales[type].split(';')[0].split('/')[1];
        this.extension = extension;
        if (extension === 'pdf') {
          this.componenteDocumento = 'pdf';
        } else if (['jpg', 'png', 'jpeg', 'tiff'].includes(extension)) {
          this.componenteDocumento = 'imagen';
          if (extension === 'tiff') {
            this.$nextTick(() => this.renderTiff());
          }
        } else {
          this.componenteDocumento = null;
        }
      } else {
        this.documentoSeleccionado = this.documentos[type];
      }
      this.mostrarModal = true;
    },
    /**
     * Renderiza un archivo TIFF en un canvas.
     */
    renderTiff() {
      const canvas = this.$refs.tiffCanvas;
      const tiff = new Tiff({ buffer: this.dataURItoBuffer(this.documentoSeleccionado) });
      const context = canvas.getContext('2d');
      canvas.width = tiff.width();
      canvas.height = tiff.height();
      context.drawImage(tiff.toCanvas(), 0, 0);
    },
    /**
     * Convierte un Data URI a un ArrayBuffer.
     * 
     * @param dataURI - El Data URI que representa la imagen en formato base64.
     * @returns {Uint8Array} - Un array de bytes que representa la imagen.
     */
    dataURItoBuffer(dataURI) {
      const base64 = dataURI.split(',')[1];
      const binary = atob(base64);
      const array = [];
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      return new Uint8Array(array);
    },
    /**
     * Abre el modal para mostrar el documento correspondiente.
     * 
     * @param {string} tipoDocumento - Tipo de documento a mostrar ('documento', 'formulaMedica', 'autorizacionMedica', 'cuotaModerada', 'pagoDomicilio').
     */
    abrirDocumento(tipoDocumento) {
      this.visibility = true;
      this.mostrarDocumento(tipoDocumento);
    },
    /**
     * Borra el documento seleccionado del tipo especificado.
     * 
     * @param {string} type - Tipo de documento a borrar ('documento', 'formulaMedica', 'autorizacionMedica', 'cuotaModerada', 'pagoDomicilio').
     */
    borrarDocumento(type) {
      this.documentos[type] = null;
    },
    /**
     * Borra el documento seleccionado del tipo especificado por medio del icono 'delete'.
     * 
     * @param {string} type - Tipo de documento a borrar ('documento', 'formulaMedica', 'autorizacionMedica', 'cuotaModerada', 'pagoDomicilio').
     */
    borrarDocumentoIcono(type) {
      const tipoDocumento = type;
      const refDocumento = this.$refs.documentoIdentidadFile;
      const refFormulaMedica = this.$refs.formulaMedicaFile;
      const refAutorizacionMedica = this.$refs.autorizacionMedicaFile;
      const refCuotaModerada = this.$refs.cuotaModeradaFile;
      const refpagoDomicilio= this.$refs.pagoDomicilioFile;

      if (tipoDocumento === 'documento' && refDocumento) {
        refDocumento.reset();
        this.documentos.documento = null;
      } else if (tipoDocumento === 'formulaMedica' && refFormulaMedica) {
        refFormulaMedica.reset();
        this.documentos.formulaMedica = null;
      } else if (tipoDocumento === 'autorizacionMedica' && refAutorizacionMedica) {
        refAutorizacionMedica.reset();
        this.documentos.autorizacionMedica = null;
      } else if (tipoDocumento === 'cuotaModerada' && refCuotaModerada) {
        refCuotaModerada.reset();
        this.documentos.cuotaModerada = null;
      } else if (tipoDocumento === 'pagoDomicilio' && refpagoDomicilio) {
        refpagoDomicilio.reset();
        this.documentos.pagoDomicilio = null;
      }
    },
    /**
     * Comprueba la validez de los documentos necesarios para la autorización médica según el tipo de solicitud.
     * 
     * Muestra un mensaje de error si algún documento requerido no está presente o si faltan datos de dirección o contacto en el caso de entrega a domicilio.
     */
    comprobarAutorizacion() {
      this.documentosRequeridos = '';
      this.sinDocumentosRequeridos = false;
      if (this.tipoSolicitud === 'SCHEDULED_DELIVERY' && (!this.documentos.documento || !this.documentos.formulaMedica)) {
        this.documentosRequeridos = 'Por favor, diligencie los campos obligatorios marcados con un (*).';
        this.sinDocumentosRequeridos = true;
        return;
      }
      if (this.tipoSolicitud === 'HOME_DELIVERY' && (!this.documentos.documento || !this.documentos.formulaMedica)) {
        this.documentosRequeridos = 'Por favor, diligencie los campos obligatorios marcados con un (*).';
        this.sinDocumentosRequeridos = true;
        return;
      }
      if (this.tipoSolicitud === 'HOME_DELIVERY' && (this.datosDomicilio.direccion === '' || this.datosDomicilio.celular === '')) {
        this.documentosRequeridos = 'Por favor, diligencie los campos obligatorios marcados con un (*).';
        this.sinDocumentosRequeridos = true;
        return;
      }
      this.infoAutorizacionMedica = true;
    },
    /**
     * Realiza una solicitud al servidor para enviar los documentos necesarios.
     * 
     * Si la solicitud es para entrega programada y algún documento obligatorio no ha sido proporcionado, se muestra un mensaje de error.
     * Si la solicitud es para entrega a domicilio y algún documento obligatorio o información de dirección no ha sido proporcionada, se muestra un mensaje de error.
     * Después de validar los documentos y la información, se preparan los datos de la solicitud y se envían al servidor.
     * 
     * Si la respuesta del servidor es exitosa, se obtiene el número de solicitud y se marca la solicitud como aprobada.
     * En caso de error durante la solicitud al servidor, se maneja el error utilizando el método 'observarError'.
     */
    async enviarDocumentos() {
      this.validateProgress = true;
      this.documentosRequeridos = '';
      this.sinDocumentosRequeridos = false;
      if (this.tipoSolicitud === 'SCHEDULED_DELIVERY' && (!this.documentos.documento || !this.documentos.formulaMedica)) {
        this.documentosRequeridos = 'Por favor, diligencie los campos obligatorios marcados con un (*).';
        this.sinDocumentosRequeridos = true;
        return;
      }
      if (this.tipoSolicitud === 'HOME_DELIVERY' && (!this.documentos.documento || !this.documentos.formulaMedica)) {
        this.documentosRequeridos = 'Por favor, diligencie los campos obligatorios marcados con un (*).';
        this.sinDocumentosRequeridos = true;
        return;
      }
      if (this.tipoSolicitud === 'HOME_DELIVERY' && (this.datosDomicilio.direccion === '' || this.datosDomicilio.celular === '')) {
        this.documentosRequeridos = 'Por favor, diligencie los campos obligatorios marcados con un (*).';
        this.sinDocumentosRequeridos = true;
        return;
      }
      if (this.tipoSolicitud === 'SCHEDULED_DELIVERY') {
        this.direccionLista = this.direccion;
        this.telefonoSolicitud = Number(this.telefonoFijo !== null ? this.telefonoFijo : 0);
        this.celularSolicitud = Number(this.celular !== null ? this.celular : 0);
        this.nombreFinal = this.nombreMunicipio;
      } else {
        this.direccionLista = this.datosDomicilio.direccion;
        this.telefonoSolicitud = Number(this.datosDomicilio.telefonoFijo !== null ? this.datosDomicilio.telefonoFijo : 0);
        this.celularSolicitud = Number(this.datosDomicilio.celular !== null ? this.datosDomicilio.celular : 0);
        this.nombreFinal = this.nombreMunicipioDomicilio;
      }
      await this.listarBarrio();
      let barrioActual = this.opcionesBarrio.find(value => value.value === this.barrioSelecionado);
      if (typeof barrioActual === 'object') {
        this.idBarrio = barrioActual.id;
      } else {
        this.idBarrio = null;
      }
      let nuevaDireccion;
      let direccionCompleta = this.direccionLista;
      const esDireccionUrbana = this.tiposVias.some(opcion => direccionCompleta.startsWith(opcion));
      let detalleDireccion = this.datoAdicionalDireccion !== '' ? this.datoAdicionalDireccion : this.detalleDireccion;
      let direccionU = direccionCompleta;
      if (esDireccionUrbana) {
        if (this.barrioSelecionado !== 'OTRO') {
          const barrioEscapado = this.barrioSelecionado.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
          const regexBarrio = new RegExp(',\\s*' + barrioEscapado, 'i');
          direccionU = direccionU.replace(regexBarrio, '');
        }
      } 
      const municipioEscapado = this.nombreFinal.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
      const regexMunicipio = new RegExp(',\\s*' + municipioEscapado, 'i');
      direccionU = direccionU.replace(regexMunicipio, '');
      if (detalleDireccion !== null && detalleDireccion !== '') {
        const direccionAdicional = detalleDireccion.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
        const regexAdicional = new RegExp(',\\s*' + direccionAdicional, 'i');
        direccionU = direccionU.replace(regexAdicional, '');
      }
      nuevaDireccion = direccionU.trim();
      if (nuevaDireccion.endsWith(',')) {
        nuevaDireccion = nuevaDireccion.slice(0, -1); 
      }
      this.documentosRequeridos = '';
      this.sinDocumentosRequeridos = false;
      const documentosSolicitud = {
        aseguradora: {
          idAseguradora: this.idAseguradora
        },
        departamento: {
          idDepartamento: this.idDepartamento
        },
        ciudad: {
          idCiudad: this.idCiudad
        },
        barrio: {
          idBarrio: this.idBarrio,
        },
        registroAfiliado:{
          idRegistro: this.idAfiliado,
          documentoDigital: this.documentoActual === null ? (this.documentos.documento !== null ? this.documentos.documento.replace(/^data:application\/pdf;base64,/, '') : '') : '',
        },
        tipoSolicitud: this.tipoSolicitud,
        direccionEntrega: nuevaDireccion,
        nombreFormula: this.documentos.formulaMedica !== null ? this.documentos.formulaMedica.replace(/^data:application\/pdf;base64,/, '') : '',
        nombreAutorizacion: this.documentos.autorizacionMedica !== null ? this.documentos.autorizacionMedica.replace(/^data:application\/pdf;base64,/, '') : '',
        nombreComprobante: (() => {
          if (this.documentos.pagoDomicilio !== null) {
            return this.documentos.pagoDomicilio.replace(/^data:application\/pdf;base64,/, '');
          } else if (this.documentos.cuotaModerada !== null) {
            return this.documentos.cuotaModerada.replace(/^data:application\/pdf;base64,/, '');
          } else {
            return '';
          }
        })(),
        numeroCelular: this.celularSolicitud,
        numeroTelefono: this.telefonoSolicitud,
        informacionAdicional: this.datoAdicionalDireccion !== '' ? this.datoAdicionalDireccion : this.detalleDireccion,
      }
      const endpoint = this.estadoRegistro === true ? 'solicitudEntrega/agregar' : 'solicitud-temporal/agregar';
      if (this.tipoSolicitud === 'HOME_DELIVERY') {
        documentosSolicitud.bodegas = {
          bodegasId: {
            idEmpresa: this.idEmpresa,
            codigoBodega: 3633,
          }
        };
        this.$http.post(`msa-external/api/${endpoint}`, documentosSolicitud)
        .then((response) => {
          this.numeroSolicitud = response.data;
          this.solicitudAprovada = true;
          this.infoAutorizacionMedica = false;
          this.validateProgress = false;
        }).catch((error) => {
          this.validateProgress = false;
          this.observarError(error);
        })
      } else {
        this.$http.get(`msa-external/api/servicios/1/${this.idCiudad}`)
        .then((response) => {
          const bodegas = response.data;
          if (bodegas.length === 1) {
            documentosSolicitud.bodegas = {
              bodegasId: {
                idEmpresa: this.idEmpresa,
                codigoBodega: bodegas[0].codigoBodega,
              }
            };
          } else {
            documentosSolicitud.bodegas = {
              bodegasId: {
                idEmpresa: this.idEmpresa,
                codigoBodega: 0,
              }
            };
          }
          this.$http.post(`msa-external/api/${endpoint}`, documentosSolicitud)
            .then((response) => {
              this.numeroSolicitud = response.data;
              this.solicitudAprovada = true;
              this.infoAutorizacionMedica = false;
            }).catch((error) => {
              this.validateProgress = false;
              this.observarError(error);
            })
            this.validateProgress = false;
        }).catch((error) => {
          this.validateProgress = false;
          this.observarError(error);
        });
      }
    },
    /**
     * Reinicia la carga de documentos, reseteando los campos de carga de documentos a su estado inicial.
     * 
     * Este método es utilizado para limpiar los campos de carga de documentos después de que se realice una solicitud.
     */
    resetCargaDeDocumentos() {
      const refDocumento = this.$refs.documentoIdentidadFile;
      const refFormulaMedica = this.$refs.formulaMedicaFile;
      const refAutorizacionMedica = this.$refs.autorizacionMedicaFile;
      const refCuotaModerada = this.$refs.cuotaModeradaFile;
      const refpagoDomicilio= this.$refs.pagoDomicilioFile;

      if (refDocumento) {
        refDocumento.reset();
      } else if (refFormulaMedica) {
        refFormulaMedica.reset();
      } else if (refAutorizacionMedica) {
        refAutorizacionMedica.reset();
      } else if (refCuotaModerada) {
        refCuotaModerada.reset();
      } else if (refpagoDomicilio) {
        refpagoDomicilio.reset();
      }
      
      this.documentos = {
        documento: null,
        formulaMedica: null,
        autorizacionMedica: null,
        cuotaModerada: null,
        pagoDomicilio: null,
      };
      this.documentosTemporales = {
        documento: null,
        formulaMedica: null,
        autorizacionMedica: null,
        cuotaModerada: null,
        pagoDomicilio: null,
      };
      this.formatoIncorrecto = {
        documento: '',
        formulaMedica: '',
        autorizacionMedica: '',
        cuotaModerada: '',
        pagoDomicilio: '',
      };
      this.documentoSeleccionado = null;
      this.tipoDocumentoActual = '';
    },
    /**
     * Cierra el diálogo de solicitud aprobada y realiza acciones adicionales.
     * 
     *  Este método se utiliza para cerrar el diálogo después de que se completa una acción, como listar los datos del afiliado y reiniciar la carga de documentos.
     */
    cerrarDialogoSolicitudAprovada() {
      this.listarDatosAfiliado();
      this.resetCargaDeDocumentos();
      this.solicitudAprovada = false;
      this.opcionPanal = null;
      this.tipoSolicitud = null;
    },
    /**
     * Valida que la tecla presionada sea un número.
     * Evita la entrada de caracteres no numéricos.
     * @param {Event} event - Evento de tecla presionada.
     */
    validarLetra(event) {
      const teclaPresionada = event.key
      if (teclaPresionada.match(/[^0-9]/)) {
        event.preventDefault();
      }
    },
    /** Configura el tamaño del campo this.datosDomicilio.municipio */
    fieldSize() {
      this.menuPropsMunDire.maxWidth = this.$refs.municipioDomicilio.$el.offsetWidth;
    },
    /**
     * Abre el diálogo de dirección y realiza las siguientes acciones:
     * - Verifica si hay una dirección ya establecida en el campo this.datosAfiliado.direccion
     * - Verifica si la dirección proporcionada es urbana o rural.
     * - Si es urbana, procesa la información de la dirección urbana.
     * - Si es rural, procesa la información de la dirección rural.
     * - Actualiza las variables de estado para mostrar u ocultar elementos en el diálogo.
     */
    async abrirDialogoDireccion(){
      this.esDireccionDomicilio = false;
      this.direccionSelecionada = this.datosAfiliado.direccion;
      this.municipioSeleccionado = this.nombreMunicipio;
      this.componenteAddress = true;
      this.onDialogAddressForm();
    },
    /**
     * Abre el diálogo de dirección y realiza las siguientes acciones:
     * - Verifica si hay una dirección ya establecida en el campo this.datosDomicilio.direccion
     * - Verifica si la dirección proporcionada es urbana o rural.
     * - Si es urbana, procesa la información de la dirección urbana.
     * - Si es rural, procesa la información de la dirección rural.
     * - Actualiza las variables de estado para mostrar u ocultar elementos en el diálogo.
     */
    async direccionDomicilio(){
      this.esDireccionDomicilio = true;
      this.direccionSelecionada = this.datosDomicilio.direccion;
      this.municipioSeleccionado = this.nombreMunicipioDomicilio;
      this.componenteAddress = true;
      this.onDialogAddressForm();
    },
    /**
     * Actualiza los detalles de la dirección en el componente principal.
     * @param param0 - El objeto que contiene los detalles de la dirección.
     */
    actualizarDireccion({ direccionLista, barrioSelecionado, barrioOptional, tiposDireccion, tipoDireccion, datoAdicionalDireccion }) {
      if (this.esDireccionDomicilio === false) {
        this.datosAfiliado.direccion = '';
        this.datosAfiliado.direccion = direccionLista;
      } else {
        this.datosDomicilio.direccion = '';
        this.datosDomicilio.direccion = direccionLista;
      }
      this.barrioSelecionado = barrioSelecionado;
      this.barrioOpcional = barrioOptional;
      this.tiposDireccion = tiposDireccion;
      this.tipoDireccion = tipoDireccion;
      this.datoAdicionalDireccion = datoAdicionalDireccion;
      this.componenteAddress = false;
    },
    /**
     * Método que se llama cuando se muestra el diálogo del formulario de dirección.
     * Se asegura de que el formulario de dirección se llene con los datos correctos.
     */
    onDialogAddressForm() {
      this.$nextTick(() => {
        if (this.$refs.addressComponent) {
          setTimeout(() => {
            this.$refs.addressComponent.llenarFormularioDireccion();
          }, 100)
        }
      });
    },
    /**
     * Obtiene la lista de departamentos basada en el país seleccionado y actualiza las opciones de departamento en el componente.
     * Las opciones se almacenan en el array 'opcionesDepartamento'.
     */
    async listarDepartamento() {
      try {
        const response = await this.$http.get(`msa-external/public/departamento/list`);
        this.opcionesDepartamento = response.data.map(item => ({
          text: `${item.departamento}`,
          value: `${item.idDepartamento}`,
        }));
      } catch (error) {
        this.observarError(error);
      }
    },
    /**
     * Método invocado cuando se produce un cambio en la selección del departamento.
     * Limpia la variable de municipio y llama al método para listar los municipios basados en el departamento seleccionado.
     */
    async onDepartamentoChange() {
      // Limpiar la variable de departamento al cambiar el país
      this.datosAfiliado.municipio = '';

      // Llamar al método para listar departamentos
      await this.listarMunicipio();
    },
    /**
     * Obtiene la lista de municipios basada en el departamento seleccionado y actualiza las opciones de municipio en el componente.
     * Las opciones se almacenan en el array 'opcionesMunicipio'.
     * 
     * En caso de error durante la solicitud al servidor, se maneja el error utilizando el método 'observarError'.
     */
    async listarMunicipio() {
      if (this.datosAfiliado.departamento !== '') {
        try {
          const response = await this.$http.get(`msa-external/public/ciudad/list/${this.datosAfiliado.departamento}`);
          this.opcionesMunicipio = response.data.map(item => ({
            text: `${item.ciudad}`,
            value: `${item.idCiudad}`,
            municipio: item.ciudad,
          }));
        } catch (error) {
          this.observarError(error);
        }
      }
    },
    /**
     * Actualiza el nombre del municipio seleccionado.
     */
    updateNombreMunicipio() {
      const selectedMunicipio = this.opcionesMunicipio.find(option => option.value === this.datosAfiliado.municipio);
      if (selectedMunicipio) {
        this.nombreMunicipio = selectedMunicipio.text;
        this.idCiudad = selectedMunicipio.value;
      } else {
        this.nombreMunicipio = '';
      }
      this.datosAfiliado.direccion = '';
    },
    /**
     * Lista los barrios disponibles en función del municipio seleccionado y los almacena en el array opcionesBarrio.
     * 
     * En caso de error durante la solicitud al servidor, se maneja el error utilizando el método 'observarError'.
     */
    async listarBarrio() {
      this.verBarrio = true;
      if (this.datosAfiliado.municipio !== '' || this.datosDomicilio.municipio !== '') {
        try {
          const response = await this.$http.get(`msa-external/public/barrio/list/${this.idCiudad}`);
          this.opcionesBarrio = response.data.map(item => ({
              text: `${item.barrio}`,
              value: `${item.barrio}`,
              barrio: item.barrio,
              id: `${item.idBarrio}`,
          }));
          this.opcionesBarrio.push({ text: 'OTRO', value: 'OTRO', barrio: 'OTRO', id: null });
        } catch (error) {
          this.observarError(error);
        }
      }
    },
    /**
     * Actualiza el nombre del municipio de domicilio seleccionado.
     */
    updateNombreMunicipioDomicilio() {
      const selectedMunicipio = this.opcionesMunicipioDomicilio.find(option => option.value === this.datosDomicilio.municipio);
      if (selectedMunicipio) {
        this.nombreMunicipioDomicilio = selectedMunicipio.text;
      } else {
        this.nombreMunicipioDomicilio = '';
      }
      this.datosDomicilio.direccion = '';
    },
    /**
     * Lista los municipios de domicilio disponibles en función del departamento seleccionado y los almacena en opcionesMunicipioDomicilio.
     * 
     * En caso de error durante la solicitud al servidor, se maneja el error utilizando el método 'observarError'.
     */
    async listarMunicipioDomicilio() {
      if (this.idDepartamento !== '') {
        try {
          const response = await this.$http.get(`msa-external/public/ciudad/list?idDepartamento=${this.idDepartamento}`);
          this.opcionesMunicipioDomicilio = response.data.map(item => ({
            text: `${item.ciudad}`,
            value: `${item.idCiudad}`,
            municipio: item.ciudad,
            id: item.idCiudad,
          }));
        } catch (error) {
          this.observarError(error);
        }
      }
    },
    /**
     * Realiza una solicitud al servidor para actualizar los datos del afiliado con la información proporcionada.
     * Después de la solicitud al servidor, si la respuesta es exitosa, se muestra un mensaje de confirmación y se actualiza la información del afiliado.
     * 
     * En caso de error durante la solicitud al servidor, se maneja el error utilizando el método 'observarError'.
     */
    async actualizar() {
      await this.listarBarrio();
      let barrioActual = this.opcionesBarrio.find(value => value.value === this.barrioSelecionado);
      if (typeof barrioActual === 'object') {
        this.idBarrio = barrioActual.id;
      } else {
        this.idBarrio = null;
      }
      
      let direccionCompleta = this.datosAfiliado.direccion;
      let nuevaDireccion;
      let indiceNumeral = direccionCompleta.indexOf('#');
      if (indiceNumeral !== -1) {
        const barrioEscapado = this.barrioSelecionado.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
        const municipioEscapado = this.nombreMunicipio.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
        const regexBarrio = new RegExp(',\\s*' + barrioEscapado, 'i');
        const regexMunicipio = new RegExp(',\\s*' + municipioEscapado, 'i');
        let direccionU = direccionCompleta.replace(regexBarrio, '').replace(regexMunicipio, '');
        nuevaDireccion = direccionU.trim();
      } else {
        const municipioEscapado = this.nombreMunicipio.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
        const regexMunicipio = new RegExp(',\\s*' + municipioEscapado, 'i');
        let direccionR = direccionCompleta.replace(regexMunicipio, '');
        nuevaDireccion = direccionR.trim();
      }
      if (nuevaDireccion.endsWith(',')) {
        nuevaDireccion = nuevaDireccion.slice(0, -1); 
      }
      const esDireccionUrbana = this.tiposVias.some(opcion => nuevaDireccion.startsWith(opcion));
      let tipoDireccion;
      if (esDireccionUrbana) {
        tipoDireccion = 'U'
      } else {
        tipoDireccion = 'R'
      }
      const datos = {
        idEmpresa: this.idEmpresa,
        idRegistro: this.idAfiliado,
        primerNombre: this.primerNombre,
        segundoNombre: this.segundoNombre,
        primerApellido: this.primerApellido,
        segundoApellido: this.segundoApellido,
        tipoDireccion: tipoDireccion,
        direccion: nuevaDireccion,
        numeroTelefono: Number(this.datosAfiliado.telefonoFijo !== '' ? this.datosAfiliado.telefonoFijo : 0),
        numeroCelular: this.datosAfiliado.celular,
        email: this.datosAfiliado.correo.toUpperCase(),
        idDepartamento: this.datosAfiliado.departamento,
        idCiudad: this.datosAfiliado.municipio,
        idBarrio: this.idBarrio,
        estadoRegistro: this.estadoRegistro,
      }
      this.$http.post("msa-external/api/registro/update", datos)
        .then((response) => {
          if (response.status === 200) {
            this.mensajeDatos = "Datos actualizados correctamente.";
            this.alertaDatos = true;
            this.clean();
            setTimeout(() => {
              this.alertaDatos = false;
              this.mensajeDatos = '';
            }, 3000);
          }
        })
        .catch((error) => {
          this.observarError(error);
        })
    },
    /**
     * Realiza una solicitud al servidor para cambiar la contraseña del afiliado.
     * Después de la solicitud al servidor, si la respuesta es exitosa, se muestra un mensaje de confirmación.
     * 
     * En caso de error durante la solicitud al servidor, se maneja el error utilizando el método 'observarError'.
     */
    guardarContraseña() {
      this.$http.get(`msa-external/api/cambiar?idkeycloak=${this.idKeycloack}&newPassword=${this.confirmarContrasena}`)
      .then((response) => {
        this.resetContrasena();
        if (response.data.status === 1) {
          this.mensajeContrasena = `${response.data.message}.`;
          this.alertaPassword = true;  
          setTimeout(() => {
            this.alertaPassword = false;
            this.mensajeContrasena = '';
          }, 3000);
        }
      })
      .catch((error) => {
        this.observarError(error);
      })
    },
    /**
     * Reinicia los campos relacionados con el cambio de contraseña.
     */
    resetContrasena() {
      this.dialogContrasena = false;
      this.contrasena = '';
      this.confirmarContrasena = '';
      this.$refs.observerContrasena.reset();
    },
    /**
     * Restablece los valores de los campos y las variables relacionadas al afiliado.
     * Establece los valores predeterminados y cierra los cuadros de diálogo abiertos.
     * Reinicia el estado de edición y activación de campos.
     * Reinicia el estado del componente de observación (refs.observer).
     */
    clean() {
      this.dialog = false;
      this.datosAfiliado.direccion = '';
      this.datosAfiliado.telefonoFijo = '';
      this.datosAfiliado.celular = '';
      this.datosAfiliado.correo = '';
      this.datosAfiliado.pais = '';
      this.datosAfiliado.departamento = '';
      this.datosAfiliado.municipio = '';
      this.barrioOpcional = '';
      this.tiposDireccion = '';
      this.tipoDireccion = '';
      this.editar = false;
      this.$refs.observer.reset();
      this.listarDatosAfiliado();
    },
    /**
     * Maneja errores capturados durante solicitudes al servidor.
     * Si el error es una 'Network Error' o es una respuesta con estado 401, establece el estado de error en el almacenamiento.
     * Si el error no corresponde a los casos anteriores, se registra en la consola.
     */
    observarError(error) {
      if (error.message === 'Network Error') {
        this.$store.commit('setError', true);
      } else if (error.response && error.response.status === 401) {
        this.$store.commit('setError', true);
      } else {
        this.manejarError(error);
      }
    },
    /**
     * Establece la propiedad en true cuando la pantalla tiene un ancho igual o menor a 959
     */
    checkScreenSize() {
      this.screenSmaller = window.innerWidth <= 1064;
      if (window.innerWidth <= 740) {
        this.sizeText = 7
      } else {
        this.sizeText = 30
      }
    },
    /**
     * Muestra una notificación global en el componente.
     *
     * @param {string} mensaje - Mensaje que se mostrará en la notificación.
     * @param {string} tipo - Tipo de la notificación (por defecto, "error").
     * @param {string} icono - Icono de la notificación (por defecto, "highlight_off").
     */
    mostrarNotificacionGlobal(mensaje, tipo, icono) {
      this.mostrarNotificacion = true;
      this.tipoNotificacion = tipo || "advertencia"; // Tipo predeterminado es "error"
      this.tipoNotificacionColor = this.obtenerColorNotificacion(this.tipoNotificacion);
      this.iconoNotificacion = icono || "highlight_off"; // Icono predeterminado es "highlight_off"
      this.mensajeNotificacion = mensaje;
      this.iconoNotificacionClase = this.obtenerClaseIcono(this.tipoNotificacion);

      // Cierra la notificación después de 5 segundos
      setTimeout(() => {
        this.cerrarNotificacion();
      }, 5000); 
    },
    /**
     * Cierra la notificación
     */
    cerrarNotificacion() {
      this.mostrarNotificacion = false;
      this.mensajeNotificacion = "";
      this.tipoNotificacion = "";
    },
    /**
     * Obtiene el color correspondiente al tipo de notificación.
     * 
     * @param {string} tipo - Tipo de notificación.
     * @returns {string} - Color de la notificación.
     */
    obtenerColorNotificacion(tipo) {
      switch (tipo) {
        case "advertencia":
          return "#f80505";
        default:
          return "#f80505"; 
      }
    },
    /**
     * Obtiene la clase de icono correspondiente al tipo de notificación.
     * 
     * @param {*} tipo  - Tipo de notificación.
     * @returns {string} - Clase de icono.
     */
    obtenerClaseIcono(tipo) {
      switch (tipo) {
        case "advertencia":
          return "advertencia-icon";
        default:
          return "advertencia-icon";
      }
    },
    /**
     * Maneja errores y muestra notificaciones correspondientes.
     * 
     * @param {*} error - Objeto de error.
     */
    manejarError(error) {

      if (error.response) {
        if (error.response.status === 503) {
          this.mostrarNotificacionGlobal("Error en la red, inténtalo más tarde.", "advertencia", "highlight_off" );
        } else {
          this.mostrarNotificacionGlobal("Error inesperado, contacta con el administrador.", "advertencia", "highlight_off");
        }
      } else {
        this.mostrarNotificacionGlobal("Error inesperado, contacta con el administrador.", "advertencia", "highlight_off");
      }
    },
  },
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
.v-file-input__clear:hover {
  opacity: 1;
  pointer-events: auto;
}
.tituloDatos {
  color: #0d47a1;
  font-size: 1.4rem;
}
.linea {
  height: 1px;
  background-color: #ccc;
}
.tituloCampo {
  color: #000000;
  font-weight: normal;
  display: block;
  padding-top: 0.5rem;
}
.datosCampo {
  color: #635f5f;
}
::v-deep .file .v-input__slot {
  padding-left: 0.3rem !important;
} 
::v-deep .file .v-text-field__prefix {
  background-color: #0d48a1dc;
  border-radius: 3px;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0 0.3rem 0 0.3rem;
  margin-right: 0.3rem;
  color: #ffffff;
}
.datosContacto {
  width: 60%;
  margin-left: 20%;
}
.boton-actualizar {
  background-color: #0d47a1 !important;
  color: #fff;
  border-radius: 18px;
}
.archivo {
  outline: 2px dashed #0d47a1;
  outline-offset: -10px;
  background: #ffffff;
  color: #0d47a1;
  padding: 0rem;
  height: 80px;
  position: relative;
  cursor: pointer;
  border: #0d47a1 solid 0.5px;
}
.archivoIncorrecto {
  outline: 2px dashed red;
  outline-offset: -10px;
  background: #ffffff;
  color: red;
  padding: 1rem;
  height: 80px;
  position: relative;
  cursor: pointer;
  border: red solid 0.5px;
}
.archivo p {
  margin-top: 1rem;
  text-align: center;
}
.archivoIncorrecto p {
  margin-top: 0rem;
  text-align: center;
}
.seleccionarArchivo {
  opacity: 0;
  width: 100%;
  height: 79px;
  position: absolute;
  cursor: pointer;
}
.sinFormula {
  color: #f80505;
  display: flex;
  justify-content: center;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 33, 33, 0.46);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9998;
}
.notificationValidation {
  position: fixed;
  top: 50% !important;
  left: 50%;
  transform: translateX(-50%);
  background-color: #5baa5e;
  color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 30vw;
}
.perfilAfiliado {
  background-color: #2b6fd4;
  color: white;
  width: 100%;
  padding: 0% !important;
  border-radius: 0.30rem;
}
.perfilAfiliado span{
  font-size: medium;
  width: 100%;
  text-align: center;
}
.fondoDialog {
  background-color: #1867c0;
  color: white;
}
.formulario-flex {
  flex-wrap: nowrap;
}
@media (max-width: 700px) {
  .formulario-flex {
    flex-wrap: wrap !important;
  }
}
::v-deep .elevation div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}
::v-deep .elevation div table thead tr th span {
  font-weight: bold;
  color: black !important;
}
::v-deep .elevation .v-data-footer {
  width: 100%;
}
::v-deep .elevation .v-data-footer__select .v-select {
  margin: 5px;
  margin-left: 10px;
}
.rotate-animation {
  animation: rotate 2s linear infinite;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.notificacion {
  position: fixed;
  top: 50px;
  right: 20px;
  padding: 15px;
  border-radius: 5px;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: opacity 0.5s ease-in-out;
}
.advertencia {
  background-color: #ffffffd7 !important;
  color: #f80505;
}
.notificacion span:last-child {
  cursor: pointer;
  margin-right: 10px;
  padding-top: 3px;
}
.advertencia-icon {
  color: #f80505;
}
.rotate-animation-notification {
  animation: rotate-notification 1s ease-in-out; 
}
@keyframes rotate-notification  {
  0% {
    transform: rotateX(180deg);
  }

  50% {
    transform: rotateX(0deg);
  }

  100% {
    transform: rotateX(180deg);
  }
}
.size {
  font-size: xxx-large;
  font-weight: bold;
}
::v-deep .notificacionError .v-data-footer {
  padding-top: 10px;
  justify-content: end;
  border: none;
}
.radio-container {
  display: flex;
  align-items: flex-start; 
}
::v-deep .radio-container .v-input--selection-controls__input {
  align-self: flex-start; 
}
::v-deep .v-text-field__details {
  padding: 0 !important;
}
::v-deep .v-autocomplete.v-select.v-input--is-focused input {
  min-width: 0%;
}
</style>